import { type ActionEvent, Controller } from "@hotwired/stimulus"

const ACTIVE_CLASS = "--active"
const TIMEOUT = 3000

export default class TooltipController extends Controller<HTMLElement> {
  declare timeoutId: number | undefined

  copy({ params }: ActionEvent): void {
    void (async (): Promise<void> => {
      const originalIcon = window.getComputedStyle(this.element).getPropertyValue("--icon-content")

      try {
        await window.navigator.clipboard.writeText(params.copy!)
        this.element.style.setProperty("--icon-content", '"\\f00c"') // fa-check
        this.show("Copied!", () => {
          this.element.style.setProperty("--icon-content", originalIcon)
        })
      } catch {
        // Ignore copy error
      }
    })()
  }

  hide(): void {
    this.element.classList.remove(ACTIVE_CLASS)
    this.resetPosition()
  }

  toggle({ params }: ActionEvent): void {
    if (this.element.classList.contains(ACTIVE_CLASS)) {
      this.hide()
    } else {
      this.show(params.message)
    }
  }

  hover({ params }: ActionEvent): void {
    this.show(params.message)
  }

  private hideAllTooltips(): void {
    this.application.controllers.forEach((controller) => {
      if (controller.identifier === this.identifier) {
        ;(controller as TooltipController).hide()
      }
    })
  }

  private resetPosition(): void {
    this.element.style.setProperty("--x-offset", "0px")
    this.element.style.setProperty("--y-offset", "0px")
  }

  private setPosition(): void {
    const isPositionBottom = this.element.classList.contains("position:bottom")
    const tooltipStyles = window.getComputedStyle(this.element, "::before")
    const { bottom, height, right } = this.element.getBoundingClientRect()
    const tooltipRight = isPositionBottom
      ? right + parseFloat(tooltipStyles.width) / 2
      : right + parseFloat(tooltipStyles.left) + parseFloat(tooltipStyles.width)
    const tooltipBottom = isPositionBottom
      ? bottom + height / 2 + parseFloat(tooltipStyles.height)
      : bottom + parseFloat(tooltipStyles.height) / 2
    const { innerHeight, innerWidth } = window

    if (tooltipRight >= innerWidth) {
      this.element.style.setProperty("--x-offset", `${tooltipRight - innerWidth - 5}px`)
    }

    if (tooltipBottom >= innerHeight) {
      this.element.style.setProperty("--y-offset", `${tooltipBottom - innerHeight - 5}px`)
    }
  }

  private show(message: string, onTimeout?: () => void): void {
    window.clearTimeout(this.timeoutId)
    this.hideAllTooltips()
    this.element.classList.add(ACTIVE_CLASS)
    this.element.style.setProperty("--tooltip-message", `"${message}"`)
    this.setPosition()

    this.timeoutId = window.setTimeout(() => {
      onTimeout?.()
      this.hide()
    }, TIMEOUT)
  }
}
